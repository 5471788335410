/* eslint-disable */
import Barba from 'barba.js/dist/barba.min';
import anime from "animejs";
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';

UIkit.use(Icons);

let app,
textTitle = [],
grid = [].slice.call(document.querySelectorAll('.grid')),
preloader,
currentGrid = 0,
theme_element,
loaders = [];

export default {
    init() {
        app = {
            applyFX: function (effect) {
                requestAnimationFrame(function () {
                    loaders.forEach(function (loader) {
                        loader.render(effect);
                    });
                });
            },
            init: function () {
                (function () {

                    if (typeof window.CustomEvent === "function") return false;

                    function CustomEvent(event, params) {
                        params = params || {bubbles: false, cancelable: false, detail: undefined};
                        var evt = document.createEvent('CustomEvent');
                        evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
                        return evt;
                    }

                    CustomEvent.prototype = window.Event.prototype;

                    window.CustomEvent = CustomEvent;
                })();
                (function () {
                    var throttle = function (type, name, obj) {
                        obj = obj || window;
                        var running = false;
                        var func = function () {
                            if (running) {
                                return;
                            }
                            running = true;
                            requestAnimationFrame(function () {
                                obj.dispatchEvent(new CustomEvent(name));
                                running = false;
                            });
                        };
                        obj.addEventListener(type, func);
                    };
                    /* init - you can init any event */
                    throttle("resize", "optimizedResize");
                })();

                this.initEvents();
                this.initTransition();
                this.initViews();
                this.initBarba();
            },
            initEvents: function () {
                Barba.Pjax.originalPreventCheck = Barba.Pjax.preventCheck;
                Barba.Pjax.preventCheck = function (evt, element) {
                    if (!Barba.Pjax.originalPreventCheck(evt, element)) {
                        return false;
                    }

                    // ignore pdf links
                    if (/.pdf/.test(element.href.toLowerCase())) {
                        return false;
                    }

                    // additional (besides .no-barba) ignore links with these classes
                    // ab-item is for wp admin toolbar links
                    var ignoreClasses = ['ab-item'];
                    for (var i = 0; i < ignoreClasses.length; i++) {
                        if (element.classList.contains(ignoreClasses[i])) {
                            return false;
                        }
                    }

                    return true;
                };
            },
            initTransition: function () {
                var HideShowTransition = Barba.BaseTransition.extend({
                    start: function () {
                        Promise
                            .all([this.newContainerLoading, this.fadeOut()])
                            .then(this.fadeIn.bind(this));
                    },

                    fadeOut: function () {
                    /*
                      var loader = new GridLoaderFX(this.oldContainer, '.grid__item');
                      var footerLoader = new GridLoaderFX(document, 'footer');
                      var navigation = $('#primary-navigation');
                      preloader = document.body.querySelector('#loader');
                      loader.render('LSAP_leave');
                    */

                        var fade_out = anime({
                            targets: this.oldContainer,
                            opacity: 0,
                            duration: 150,
                            easing: 'easeInCirc'
                        });

                        return fade_out.finished.then(() => {
                            window.scrollTo({
                                top: 0,
                                behavior: "smooth"
                            });
                        });
                    },

                    fadeIn: function () {
                        var _this = this;
                        const newContainer = this.newContainer;
                        /*
                        ImagesLoaded(newContainer, function (instance) {
                        });
                          requestAnimationFrame(function () {
                                new GridLoaderFX(document.body, '.barba-container').render('LSAP_init');
                            });
                            app.applyFX('LSAP');
                            app.masonryLayout();
                        */
                        var fade_in = anime({
                            targets: _this.newContainer,
                            opacity: 1,
                            duration: 150,
                            easing: 'easeInCirc'
                        });
                        _this.done();

                    }
                });
                Barba.Pjax.getTransition = function () {
                    return HideShowTransition;
                };
            },
            initViews: function () {
                var ExtraView = Barba.BaseView.extend({
                    onEnter: function () {
                        this.onExtraEnter();
                    },
                    onLeave: function () {
                        this.onExtraLeave();
                    },
                    onExtraEnter: function () {
                    },
                    onExtraLeave: function () {

                    }
                });
                var AppView = ExtraView.extend({
                    namespace: 'app',
                    onExtraEnter: function () {
                    },
                    onEnterCompleted: function () {
                    },
                });
                AppView.init();
            },
            initBarba: function () {
                Barba.Pjax.start();
                Barba.Dispatcher.on('initStateChange', function () {
                    if (typeof ga !== 'function' || typeof fbq !== 'function' || Barba.HistoryManager.history.length <= 1) {
                        return;
                    }
                    ga('send', 'pageview', window.location.pathname);
                    fbq('track', 'PageView');
                });
            },
        };
    },
    finalize() {
        preloader = document.body.querySelector('#loader');
        var body = document.body;
        var container = body.querySelector('.barba-container');
        var fade_in = anime({
            targets: container,
            opacity: 1,
            duration: 150,
            easing: 'easeInCirc'
        });
        /*
            ImagesLoaded(container, function (instance) {
            requestAnimationFrame(function () {
                new GridLoaderFX(body, '.barba-container').render('LSAP');
            });
        });
        */
        app.init();
    },
};
/* eslint-enable */
